import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.accountingApi = <EagleModuleApiInterface>{
    records: (params = {}) => api.request({
      url: `/api/admin/accounting/records`,
      type: 'get',
      params,
    }),
    recommendedCost: () => api.request({
      url: `/api/admin/accounting/recommended-cost`,
      type: 'get',
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/accounting`,
      type: 'post',
      params,
    }),
    saveRecords: (create, update, _delete) => api.request({
      url: `/api/admin/accounting/records`,
      type: 'put',
      params: {
        create: create ?? [],
        update: update ?? [],
        delete: _delete ?? [],
      }
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/accounting/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/accounting/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/accounting/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
